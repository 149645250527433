export const SET_OWNER_NAME = "coinshift/global/SET_OWNER_NAME";
export const SET_SAFE_ADDRESS = "coinshift/global/SET_SAFE_ADDRESS";
export const SET_OWNER_DETAILS = "coinshift/global/SET_OWNER_DETAILS";
export const SET_OWNERS_AND_THRESHOLD =
  "coinshift/global/SET_OWNERS_AND_THRESHOLD";
export const SET_ORGANISATION_TYPE = "coinshift/global/SET_ORGANISATION_TYPE";
export const CLEAR_GLOBAL_STATE = "coinshift/global/CLEAR_GLOBAL_STATE";
export const SET_READ_ONLY = "coinshift/global/SET_READ_ONLY";
export const SET_DATA_SHARING = "coinshift/global/SET_DATA_SHARING";
export const SET_GAS_MODE = "coinshift/global/SET_GAS_MODE";

export const GET_SAFE_INFO = "coinshift/global/GET_SAFE_INFO";
export const GET_SAFE_INFO_SUCCESS = "coinshift/global/GET_SAFE_INFO_SUCCESS";
export const GET_SAFE_INFO_ERROR = "coinshift/global/GET_SAFE_INFO_ERROR";

export const NUDGE_TO_V2 = "coinshift/global/NUDGE_TO_V2";
export const NUDGE_TO_V2_SUCCESS = "coinshift/global/NUDGE_TO_V2_SUCCESS";
export const NUDGE_TO_V2_ERROR = "coinshift/global/NUDGE_TO_V2_ERROR";

export const GET_V2_MIGRATION_STATUS =
  "coinshift/global/GET_V2_MIGRATION_STATUS";
export const GET_V2_MIGRATION_STATUS_SUCCESS =
  "coinshift/global/GET_V2_MIGRATION_STATUS_SUCCESS";
export const GET_V2_MIGRATION_STATUS_ERROR =
  "coinshift/global/GET_V2_MIGRATION_STATUS_ERROR";

export const GET_V2_WAITLIST_STATUS = "coinshift/global/GET_V2_WAITLIST_STATUS";
export const GET_V2_WAITLIST_STATUS_SUCCESS =
  "coinshift/global/GET_V2_WAITLIST_STATUS_SUCCESS";
export const GET_V2_WAITLIST_STATUS_ERROR =
  "coinshift/global/GET_V2_WAITLIST_STATUS_ERROR";
