export const DEFAULT_LABEL_COLOR = "#D0DCFD";

export const COLORS = [
  DEFAULT_LABEL_COLOR,
  "#FDE7D2",
  "#DBD3F3",
  "#CCF5FE",
  "#FEDBD8",
  "#E9F5D0",
  "#FAF6EE",
  "#DCD4C6",
];
