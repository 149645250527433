import styled from "styled-components/macro";

export default styled.div`
  padding: 4.2rem;

  .title {
    font-size: 1.6rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    margin-bottom: 1rem;
  }

  .subtitle {
    font-size: 1.4rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
    margin-bottom: 1rem;
  }

  .add-people-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 2rem;
  }

  @media (max-width: 600px) {
    .wrapper {
      flex-wrap: wrap;
      grid-gap: 1rem;
    }
  }
`;
