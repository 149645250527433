export const CREATE_MULTISIG_TRANSACTION =
  "coinshift/multisig/CREATE_MULTISIG_TRANSACTION";
export const CREATE_MULTISIG_TRANSACTION_SUCCESS =
  "coinshift/multisig/CREATE_MULTISIG_TRANSACTION_SUCCESS";
export const CREATE_MULTISIG_TRANSACTION_ERROR =
  "coinshift/multisig/CREATE_MULTISIG_TRANSACTION_ERROR";

export const CONFIRM_MULTISIG_TRANSACTION =
  "coinshift/multisig/CONFIRM_MULTISIG_TRANSACTION";
export const CONFIRM_MULTISIG_TRANSACTION_SUCCESS =
  "coinshift/multisig/CONFIRM_MULTISIG_TRANSACTION_SUCCESS";
export const CONFIRM_MULTISIG_TRANSACTION_ERROR =
  "coinshift/multisig/CONFIRM_MULTISIG_TRANSACTION_ERROR";

export const SUBMIT_MULTISIG_TRANSACTION =
  "coinshift/multisig/SUBMIT_MULTISIG_TRANSACTION";
export const SUBMIT_MULTISIG_TRANSACTION_SUCCESS =
  "coinshift/multisig/SUBMIT_MULTISIG_TRANSACTION_SUCCESS";
export const SUBMIT_MULTISIG_TRANSACTION_ERROR =
  "coinshift/multisig/SUBMIT_MULTISIG_TRANSACTION_ERROR";

export const GET_MULTISIG_TRANSACTIONS =
  "coinshift/multisig/GET_MULTISIG_TRANSACTIONS";
export const GET_MULTISIG_TRANSACTIONS_SUCCESS =
  "coinshift/multisig/GET_MULTISIG_TRANSACTIONS_SUCCESS";
export const GET_MULTISIG_TRANSACTIONS_ERROR =
  "coinshift/multisig/GET_MULTISIG_TRANSACTIONS_ERROR";

export const GET_MULTISIG_TRANSACTION_BY_ID =
  "coinshift/multisig/GET_MULTISIG_TRANSACTION_BY_ID";
export const GET_MULTISIG_TRANSACTION_BY_ID_SUCCESS =
  "coinshift/multisig/GET_MULTISIG_TRANSACTION_BY_ID_SUCCESS";
export const GET_MULTISIG_TRANSACTION_BY_ID_ERROR =
  "coinshift/multisig/GET_MULTISIG_TRANSACTION_BY_ID_ERROR";

export const CREATE_OR_UPDATE_TRANSACTION_NOTE =
  "coinshift/multisig/CREATE_OR_UPDATE_TRANSACTION_NOTE";

export const UPDATE_TRANSACTION_NOTE_DATA =
  "coinshift/multisig/UPDATE_TRANSACTION_NOTE_DATA";

export const CLEAR_MULTISIG_TRANSACTION_HASH =
  "coinshift/multisig/CLEAR_MULTISIG_TRANSACTION_HASH";

export const CLEAR_MULTISIG_TRANSACTION_DETAILS =
  "coinshift/multisig/CLEAR_MULTISIG_TRANSACTION_DETAILS";

export const GET_LABELS = "coinshift/transactions/GET_LABELS";
export const GET_LABELS_SUCCESS = "coinshift/transactions/GET_LABELS_SUCCESS";
export const GET_LABELS_ERROR = "coinshift/transactions/GET_LABELS_ERROR";

export const CREATE_OR_UPDATE_LABEL =
  "coinshift/transactions/CREATE_OR_UPDATE_LABEL";

export const CREATE_TRANSACTION_LABELS =
  "coinshift/transactions/CREATE_TRANSACTION_LABELS";
export const UPDATE_TRANSACTION_LABELS =
  "coinshift/transactions/UPDATE_TRANSACTION_LABELS";

export const UPDATE_TRANSACTION_LABELS_DATA =
  "coinshift/transactions/UPDATE_TRANSACTION_LABELS_DATA";
