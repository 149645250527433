import { GNOSIS_SAFE_TRANSACTION_ENDPOINTS } from "../constants/endpoints";
import { useActiveWeb3React } from "./index";
import { useSelector } from "react-redux";
import {
  makeSelectOwnerSafeAddress,
  makeSelectSafeVersion,
} from "../store/global/selectors";
import { ethers } from "ethers";
import semverSatisfies from "semver/functions/satisfies";
import { RPC_URLS } from "utils/initOnboard";

export const useEstimateTransaction = () => {
  const { chainId } = useActiveWeb3React();
  const safeAddress = useSelector(makeSelectOwnerSafeAddress());
  const safeVersion = useSelector(makeSelectSafeVersion());

  const estimateGasTransactionService = async ({
    to,
    value,
    data,
    operation,
  }) => {
    const baseGas = 100000;

    const estimateResponse = await fetch(
      `${GNOSIS_SAFE_TRANSACTION_ENDPOINTS[chainId]}${safeAddress}/multisig-transactions/estimations/`,
      {
        method: "POST",
        body: JSON.stringify({
          safe: safeAddress,
          to,
          value,
          data,
          operation,
        }),
        headers: {
          "content-type": "application/json",
        },
      }
    );
    const { safeTxGas } = await estimateResponse.json();
    // safeTxGas fallback - when polygon estimate fails
    // Note: larger tx might fail due to OOG
    return Number(safeTxGas || 100000) + baseGas;
  };

  const estimateTransaction = async ({
    execTransactionParams,
    multiSendParams,
  }) => {
    try {
      const { from, to, value, data } = execTransactionParams;

      const provider = new ethers.providers.JsonRpcProvider(RPC_URLS[chainId]);
      const isVersionAfterV130 = semverSatisfies(safeVersion, ">=1.3.0");
      const estimatedGas = (
        await provider.estimateGas({ from, to, data, value })
      ).toNumber();

      console.log({ estimatedGas, isVersionAfterV130 });
      // for v1.1.1 safes, overshooting by 10x
      return isVersionAfterV130 ? estimatedGas : estimatedGas * 10;
    } catch (error) {
      console.error(
        `Error estimating gas for transaction using node eth_estimateGas`,
        error
      );
      const { to, value, data, operation } = multiSendParams;
      return estimateGasTransactionService({ to, value, data, operation });
    }
  };

  return {
    estimateTransaction,
  };
};
