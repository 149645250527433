import styled from "styled-components/macro";

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.2rem;
  border: solid 0.1rem #dddcdc;
  background-color: #ffffff;
`;
