import styled from "styled-components/macro";

export default styled.div`
  text-align: center;

  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #373737;
    margin-top: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  padding-bottom: 1rem;
`;
