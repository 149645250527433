import styled from "styled-components/macro";

export const Bullet = styled.span`
  background-color: #ff4660;
  width: 0.7rem;
  height: 0.7rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  display: inline-block;
`;
